import * as React from "react";
import { navigate } from "gatsby";

const IndexPage = () => {
  React.useEffect(() => {
    navigate(process.env.WEB_GATEWAY_URL + '/oauth2/authorization/web-gateway');
  }, []);

  return <div></div>;
};

export default IndexPage;